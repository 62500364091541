import React from "react"
// import Header from "../components/Header"
import Hero from "../components/Hero"
// import SEO from "../components/SEO"
import SocialProof from "../components/SocialProof"
import Banner from "../components/Banner"
import Features from "../components/Features"
import Testimonial from "../components/Testimonial"
import App from "../components/App"
// import Footer from "../components/Footer"
import PhoneSe from "../images/phone_se.png"

import Layout from "../components/layout"

const Home = ({ data }) => (
  <Layout logo="https://assets.jointacademy.com/logos/jointacademy_logo_pos.svg">
    <Hero token="321321" />
    <SocialProof recommendData="96%" gradeData="4.8" patientData="30 000" />
    <Banner />
    <Features />
    <Testimonial />
    <App Phone={PhoneSe} />
  </Layout>
)

export default Home
